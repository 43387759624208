import React from "react";
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBContainer
} from "mdbreact";

export default function CarouselPage({ carouselArray }) {
  return (
    <MDBContainer style={{ padding: "0 0 0 0" }}>
      <MDBCarousel
        activeItem={1}
        length={carouselArray.length}
        showControls={carouselArray.length <= 1 ? false : true}
        showIndicators={carouselArray.length <= 1 ? false : true}
        className="z-depth-1"
        style={{ padding: "0px, 0px" }}
      >
        <MDBCarouselInner>
          {carouselArray &&
            carouselArray.map((item, index) => {
              let indexNumber = index + 1;
              return (
                <MDBCarouselItem key={index} itemId={indexNumber.toString()}>
                  <MDBView>
                    <img
                      className="d-block w-100"
                      src={item.trainingPhotoSlide.url}
                      alt={item.trainingPhotoSlide.alt}
                    />
                  </MDBView>
                </MDBCarouselItem>
              );
            })}
        </MDBCarouselInner>
      </MDBCarousel>
    </MDBContainer>
  );
}
