import React from "react";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding-left: 3rem;
  padding-right: 3rem;

  padding-top: 4rem;
  min-height: 100vh;

  @media only screen and (max-width: 900px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media only screen and (max-width: 450px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
