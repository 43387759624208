import React from "react";
import {
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCard,
  MDBCardText
} from "mdbreact";
import { RichText } from "prismic-reactjs";

import Fade from "react-reveal/Fade";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Redirect, withRouter } from "react-router-dom";
import Loader from "../../animations/loader/index";
import { Wrapper } from "./styles";
import JumboTron from "../../components/Jumbotron/index";

function Project({ match }) {
  const { slug } = match.params;
  const { loading, error, data } = useQuery(PROJECT_PAGE, {
    variables: { slug }
  });

  if (error) {
    return <Redirect to="/404" />;
  }

  return (
    <Wrapper>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          {data.allProjectPosts.edges.length === 1 ? (
            <Fade>
              <JumboTron
                imageUrl={
                  data.allProjectPosts.edges[0].node.projectPostImage &&
                  data.allProjectPosts.edges[0].node.projectPostImage.url
                }
              />
              <MDBCard>
                <MDBCardBody>
                  <MDBCardTitle className="title">
                    {data.allProjectPosts.edges[0].node.projectPostTitle &&
                      data.allProjectPosts.edges[0].node.projectPostTitle[0]
                        .text}
                  </MDBCardTitle>
                  <MDBCardText
                    style={{
                      fontSize: "1rem",
                      fontWeight: "400",
                      lineHeight: "1.5"
                    }}
                  >
                    <RichText
                      style={{ fontWeight: "400" }}
                      render={
                        data.allProjectPosts.edges[0].node.projectPostCopy
                      }
                    />
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </Fade>
          ) : (
            <Redirect to="/404" />
          )}
        </React.Fragment>
      )}
    </Wrapper>
  );
}

export default withRouter(Project);

const PROJECT_PAGE = gql`
  query projectPage($slug: String) {
    allProjectPosts(uid: $slug) {
      edges {
        node {
          projectPostTitle
          projectPostImage
          projectPostMedia {
            ... on _ExternalLink {
              url
            }
          }
          projectPostCopy
        }
      }
    }
  }
`;
