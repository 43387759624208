import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { RichText } from "prismic-reactjs";
import { Redirect } from "react-router-dom";
import Fade from "react-reveal/Fade";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText
} from "mdbreact";
import "./style.css";

import Loader from "../../animations/loader/index";
import { StyledLink, Grid } from "./styles";

export default function Projects() {
  const { loading, error, data } = useQuery(PROJECT_PAGE);
  if (error) {
    return <Redirect to="/404" />;
  }

  return (
    <MDBContainer style={{ minHeight: "100vh" }} className="my-5 pt-5">
      {loading ? (
        <Loader />
      ) : (
        <Fade>
          <div className="pt-4">
            <h1>Projects</h1>
            <RichText
              render={data.allProjectss.edges[0].node.projectPageDescription}
            />
            <Grid>
              {data.allProjectss.edges[0].node.projectPostList &&
                data.allProjectss.edges[0].node.projectPostList.map(
                  (item, index) => {
                    const {
                      _meta,
                      projectPostTitle,
                      projectPostImage,
                      projectPostPreview
                    } = item.projectPostRelation;
                    return (
                      <StyledLink
                        key={_meta && _meta.id}
                        to={`/project/${_meta.uid && _meta.uid}`}
                      >
                        <MDBCard>
                          <MDBCardImage
                            className="img-fluid"
                            src={projectPostImage && projectPostImage.url}
                            waves
                          />
                          <MDBCardBody>
                            <MDBCardTitle className="title">
                              {projectPostTitle && projectPostTitle[0].text}
                            </MDBCardTitle>
                            <MDBCardText>
                              <RichText
                                render={
                                  projectPostPreview && projectPostPreview
                                }
                              />
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBCard>
                      </StyledLink>
                    );
                  }
                )}
            </Grid>
          </div>
        </Fade>
      )}
    </MDBContainer>
  );
}

const PROJECT_PAGE = gql`
  query projectPages {
    allProjectss {
      edges {
        node {
          title
          projectPageDescription
          projectPostList {
            projectPostRelation {
              ... on ProjectPost {
                _meta {
                  id
                  uid
                }
                projectPostTitle
                projectPostSlug
                projectPostPreview
                projectPostImage
              }
            }
          }
        }
      }
    }
  }
`;
