import { Link } from "react-router-dom";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  padding-top: 2rem;
  grid-gap: 3rem;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledLink = styled(Link)`
  height: 100%;
  text-decoration: none;
  color: none;
`;
