import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard } from "mdbreact";
import Fade from "react-reveal/Fade";
import { RichText } from "prismic-reactjs";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Redirect } from "react-router-dom";
import ResearchByCountry from "./researchByCountry";
import Twitter from "../../components/Twitter";
import Loader from "../../animations/loader/index";
import "./style.css";

export default function Research() {
  const { loading, error, data } = useQuery(RESEARCH_PAGE);
  if (error) {
    return <Redirect to="/404" />;
  }
  return (
    <MDBContainer style={{ minHeight: "100vh" }} className="my-5 pt-5">
      {loading ? (
        <Loader />
      ) : (
        <Fade>
          <div className="pt-4">
            <h1>Research</h1>
            <RichText
              render={
                data.allResearch_pages.edges[0].node.description &&
                data.allResearch_pages.edges[0].node.description
              }
            />
            <MDBRow>
              <MDBCol md="9">
                <MDBCard
                  style={{ padding: "15px 30px" }}
                  className="pb-4 postion"
                >
                  <h2 style={{ fontWeight: "bolder" }}>Latest Research</h2>
                  <hr
                    style={{
                      marginTop: "0",
                      border: "solid 1px gray",
                      backgroundColor: "gray"
                    }}
                  />
                  <MDBRow>
                    {!true ? null : (
                      <MDBCol md="12">
                        <div>Articles coming soon.</div>
                      </MDBCol>
                    )}
                  </MDBRow>
                </MDBCard>

                <MDBCard className="mt-5" style={{ padding: "15px 30px" }}>
                  <h2 style={{ fontWeight: "bolder" }}>Related Research</h2>
                  <hr
                    style={{
                      marginTop: "0",
                      border: "solid 1px gray",
                      backgroundColor: "gray"
                    }}
                  />

                  <ResearchByCountry
                    countryName="Canada"
                    countryArray={
                      data.allResearch_pages.edges[0].node.canadaResearch
                    }
                  />
                  <ResearchByCountry
                    countryName="United States"
                    countryArray={
                      data.allResearch_pages.edges[0].node.usaResearch
                    }
                  />
                  <ResearchByCountry
                    countryName="United Kingdom"
                    countryArray={
                      data.allResearch_pages.edges[0].node.ukResearch
                    }
                  />
                </MDBCard>
              </MDBCol>

              <MDBCol md="3" style={{ padding: "30px 30px 0 30px" }}>
                <Twitter handle="polbots" limit="5" />
              </MDBCol>
            </MDBRow>
            ;
          </div>
        </Fade>
      )}
    </MDBContainer>
  );
}

const RESEARCH_PAGE = gql`
  query researchPage {
    allResearch_pages {
      edges {
        node {
          description
          canadaResearch {
            researchTitle
            researchImage
            researchCopy
            researchLink {
              ... on _ExternalLink {
                url
              }
            }
          }
          usaResearch {
            researchTitle
            researchImage
            researchCopy
            researchLink {
              ... on _ExternalLink {
                url
              }
            }
          }
          ukResearch {
            researchTitle
            researchImage
            researchCopy
            researchLink {
              ... on _ExternalLink {
                url
              }
            }
          }
        }
      }
    }
  }
`;
