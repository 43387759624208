import React from "react";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBRow
} from "mdbreact";
import { Redirect } from "react-router-dom";
import { RichText } from "prismic-reactjs";
import { useQuery } from "@apollo/react-hooks";
import Loader from "../../animations/loader/index";

import gql from "graphql-tag";

import Twitter from "../../components/Twitter";
import CarouselPage from "../../components/CarouselPage";
import Fade from "react-reveal/Fade";

export default function Training() {
  const { loading, error, data } = useQuery(TRAINING_PAGE);
  if (error) {
    return <Redirect to="/404" />;
  }
  return (
    <MDBContainer style={{ minHeight: "100vh" }} className="my-5 pt-5">
      {loading ? (
        <Loader />
      ) : (
        <Fade>
          <div className="pt-4">
            <h1>Training</h1>
            <RichText
              render={data.allTrainings.edges[0].node.trainingDescription}
            />
            <MDBRow>
              <MDBCol md="8">
                {data.allTrainings.edges[0].node.trainingPosts &&
                  data.allTrainings.edges[0].node.trainingPosts.map(
                    (item, index) => {
                      const { trainingPostRelation } = item;
                      return (
                        <MDBCard key={index} style={{ paddingBottom: "2rem" }}>
                          <CarouselPage
                            carouselArray={
                              trainingPostRelation.training_photo_gallery_post
                            }
                          />
                          <MDBCardBody>
                            <MDBCardTitle>
                              {trainingPostRelation.training_title_post}
                            </MDBCardTitle>
                            <MDBCardText>
                              <RichText
                                render={
                                  trainingPostRelation.training_description_post
                                }
                              />
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBCard>
                      );
                    }
                  )}
              </MDBCol>
              <MDBCol md="4" style={{ padding: "30px 30px 0 30px" }}>
                <Twitter handle="kolga" limit="3" />
              </MDBCol>
            </MDBRow>
          </div>
        </Fade>
      )}
    </MDBContainer>
  );
}

const TRAINING_PAGE = gql`
  query trainingPage {
    allTrainings {
      edges {
        node {
          trainingDescription
          trainingPosts {
            trainingPostRelation {
              ... on Training_post {
                training_title_post
                training_date_post
                training_description_post
                training_photo_gallery_post {
                  trainingPhotoSlide
                }
              }
            }
          }
        }
      }
    }
  }
`;
