import React from "react";
import { RichText } from "prismic-reactjs";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardText
} from "mdbreact";

export default function ResearchByCountry({ countryName, countryArray }) {
  return (
    <React.Fragment>
      <h4 className="mt-4" style={{ fontWeight: "bold" }}>
        {countryName}
      </h4>
      <MDBRow>
        {countryArray &&
          countryArray.map((item, index) => {
            const {
              researchLink,
              researchTitle,
              researchImage,
              researchCopy
            } = item;
            return (
              <MDBCol
                key={researchTitle && researchTitle}
                md="12"
                style={{ padding: "15px" }}
              >
                <a href={researchLink && researchLink.url} target="_blank">
                  <MDBCard>
                    <MDBRow>
                      <MDBCol md="4" className="customMargin">
                        <MDBCardImage
                          src={researchImage && researchImage.url}
                          className="img-fluid"
                        />
                      </MDBCol>
                      <MDBCol md="8">
                        <MDBCardBody>
                          <MDBCardText style={{ fontWeight: "bold" }}>
                            <RichText render={researchCopy && researchCopy} />
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </a>
              </MDBCol>
            );
          })}
      </MDBRow>
    </React.Fragment>
  );
}
