import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

const TwitterContainer = (props) => {
    return (
      <section className="twitterContainer">
        <div className="twitter-embed">
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName={props.handle}
            options={{
              tweetLimit: props.limit,
              width: "100%",
              height: "100%"
            }}
            theme="dark"
            noHeader={true}
            noBorders={true}
            noFooter={true}
          ></TwitterTimelineEmbed>
        </div>
      </section>
    );
  };
  
  export default TwitterContainer;