import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCollapse
} from "mdbreact";
import { RichText } from "prismic-reactjs";
import "./style.css";

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: ""
    };
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  };

  render() {
    return (
      <MDBCard id={this.props.id}>
        <MDBRow>
          <MDBCol md="4" className="customMargin">
            <img
              src={this.props.image}
              style={{ width: "100%", height: "210px", objectFit: "cover" }}
              alt={this.props.headline}
            />
          </MDBCol>
          <MDBCol md="8">
            <MDBCardBody>
              <MDBCardTitle style={{ fontWeight: "bold", color: "black" }}>
                {this.props.headline}
              </MDBCardTitle>
              <MDBCardText>{this.props.byline}</MDBCardText>
              <MDBCardText style={{ fontWeight: "bold" }}>
                {this.props.date}
              </MDBCardText>
              <MDBBtn
                size="sm"
                color="black"
                style={{ margin: "0" }}
                onClick={this.toggleCollapse(this.props.id + "Collapse")}
              >
                Read article
              </MDBBtn>
            </MDBCardBody>
          </MDBCol>
          <MDBCol md="12" className="pt-3">
            <MDBCardBody className="py-0">
              <MDBCollapse
                id={this.props.id + "Collapse"}
                isOpen={this.state.collapseID}
              >
                <MDBCardText className="pb-3">
                  <RichText render={this.props.article} />
                </MDBCardText>
              </MDBCollapse>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    );
  }
}

export default Card;
