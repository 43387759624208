import styled from "styled-components";

export const LoaderWrapper = styled.div`
  .preloader .preloader-spin {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1001;
    width: 80px;
    height: 80px;
    margin: -40px 0 0 -40px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: black;
    -webkit-animation: PreloaderSpin 2s linear infinite;
    animation: PreloaderSpin 2s linear infinite;
    display: block;
    border-top-color: black;
  }

  .preloader-spin:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: black;
    -webkit-animation: PreloaderSpin 3s linear infinite;
    animation: PreloaderSpin 3s linear infinite;
    border-top-color: black;
  }

  .preloader-spin:after {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: black;
    -webkit-animation: PreloaderSpin 1.5s linear infinite;
    animation: PreloaderSpin 1.5s linear infinite;
    border-top-color: black;
  }

  .preloader {
    /* position: fixed; */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999999;
    opacity: 1;
    background: #fff;
  }

  @-webkit-keyframes PreloaderSpin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes PreloaderSpin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
