import React from 'react';
import * as ROUTES from '../../constants/routes';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon, MDBHamburgerToggler } from 'mdbreact';
import logoDark from '../../image/logoDark.png';
import './style.css'

class Nav extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          collapse: false,
      };
      this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
        collapse: !this.state.collapse,
      });
  }

  triggerBurger () {
    document.getElementById('hamburger1').click()
  }

  render() {
    const bgBlack = {backgroundColor: 'black'}
    return(
      <MDBNavbar style={bgBlack} dark expand="md" scrolling fixed="top">
        <MDBNavbarBrand href={ROUTES.LANDING} onClick={ this.triggerBurger }>
          <img src={logoDark} alt="" style={{width: '125px', height: 'auto'}}/>
        </MDBNavbarBrand>
        <MDBHamburgerToggler id="hamburger1" onClick={ this.onClick } />
        <MDBCollapse isOpen = { this.state.collapse } navbar>
          <MDBNavbarNav left className='fullHeight'>
            <MDBNavItem>
                <MDBNavLink to={ROUTES.ABOUT} onClick={ this.triggerBurger }>About</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
                <MDBNavLink to={ROUTES.PROJECT} onClick={ this.triggerBurger }>Projects</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
                <MDBNavLink to={ROUTES.RESEARCH} onClick={ this.triggerBurger }>Research</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to={ROUTES.TRAINING} onClick={ this.triggerBurger }>Training</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to={ROUTES.RESOURCES} onClick={ this.triggerBurger }>Resources</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to={ROUTES.NEWS} onClick={ this.triggerBurger }>News</MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
          <MDBNavbarNav right>
            <MDBNavItem>
              <a href="https://www.facebook.com/memetix.org/" target="_blank" className="ml-auto">
              <MDBIcon fab icon="facebook-f" size="lg" className="white-text" onClick={ this.triggerBurger }/>
              </a>
              <a href = "mailto: info@memetix.org" className="mx-4">
              <MDBIcon far icon="envelope" size="lg" className="white-text" onClick={ this.triggerBurger }/>
              </a>
              <a href="https://twitter.com/memetixx" target="_blank" className="mr-auto">
              <MDBIcon fab icon="twitter" size="lg" className="white-text" onClick={ this.triggerBurger }/>
              </a>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }
}
export default Nav;