import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Fade from "react-reveal/Fade";
import { Redirect } from "react-router-dom";
import { Grid } from "./styles";
import { RichText } from "prismic-reactjs";
import Loader from "../../animations/loader/index";

import {
  MDBContainer,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardText
} from "mdbreact";

export default function Research() {
  const { loading, error, data } = useQuery(RESEARCH_PAGE);
  if (error) {
    return <Redirect to="/404" />;
  }
  return (
    <MDBContainer style={{ minHeight: "100vh" }} className="my-5 pt-5">
      {loading ? (
        <Loader />
      ) : (
        <Fade>
          <div className="pt-4">
            <h1>Resources</h1>
            <RichText
              render={
                data.allResourcess.edges[0].node.resourceDescription &&
                data.allResourcess.edges[0].node.resourceDescription
              }
            />
            <Grid>
              {data.allResourcess.edges[0].node.resourceList &&
                data.allResourcess.edges[0].node.resourceList.map(
                  (item, index) => {
                    return (
                      <MDBCard key={index}>
                        <a href={item.resourceMedialink.url} target="_blank">
                          <MDBCardImage
                            src={item.resourceImage.url}
                            style={{ width: "100%", height: "auto" }}
                          />
                          <MDBCardBody>
                            <MDBCardText style={{ fontWeight: "bold" }}>
                              <RichText render={item.resourceCopy} />
                            </MDBCardText>
                          </MDBCardBody>
                        </a>
                      </MDBCard>
                    );
                  }
                )}
            </Grid>
          </div>
        </Fade>
      )}
    </MDBContainer>
  );
}

const RESEARCH_PAGE = gql`
  query resourcePage {
    allResourcess {
      edges {
        node {
          resourceDescription
          resourceList {
            resourceCopy
            resourceImage
            resourceMedialink {
              ... on _ExternalLink {
                url
              }
            }
          }
        }
      }
    }
  }
`;
