export const LANDING = "/";
export const SIGN_IN = "/signin";
export const NEWS = "/news";
export const RESEARCH = "/research";
export const TRAINING = "/training";
export const ABOUT = "/about";
export const ADMIN = "/admin";
export const PROJECT = "/project";
export const PROJECTPAGE = "/project/:slug";
export const RESOURCES = "/resources";
export const ERROR = "/404";
