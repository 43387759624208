import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import News from "./pages/News";
import Research from "./pages/Research";
import Training from "./pages/Training";
import About from "./pages/About";

import Footer from "./components/Footer";
import Projects from "./pages/Projects";
import Project from "./pages/Project/index";
import Resources from "./pages/Resources";
import * as ROUTES from "./constants/routes";
import ErrorPage from "./pages/Error";
import ScrollToTop from "./constants/scrollToTop";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Nav />
        <Switch>
          <Route exact path={ROUTES.LANDING} component={Home} />
          <Route exact path={ROUTES.ABOUT} component={About} />
          <Route exact path={ROUTES.NEWS} component={News} />
          <Route exact path={ROUTES.PROJECT} component={Projects} />
          <Route exact path={ROUTES.PROJECTPAGE} component={Project} />
          <Route exact path={ROUTES.RESEARCH} component={Research} />
          <Route exact path={ROUTES.TRAINING} component={Training} />
          <Route exact path={ROUTES.RESOURCES} component={Resources} />
          <Route exact path={ROUTES.ERROR} component={ErrorPage} />
        </Switch>
        <Footer />
      </ScrollToTop>
    </Router>
  );
};

export default App;
