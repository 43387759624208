import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard } from "mdbreact";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Fade from "react-reveal/Fade";
import { Redirect } from "react-router-dom";

import Loader from "../../animations/loader/index";

import NewsCard from "../../components/newsCard";
import BlogCard from "../../components/blogCard";
import "./style.css";

export default function News() {
  const { loading, error, data } = useQuery(ALL_HOME_PAGE);
  if (error) {
    return <Redirect to="/404" />;
  }

  return (
    <MDBContainer style={{ minHeight: "100vh" }} className="my-5 pt-5">
      {loading ? (
        <Loader />
      ) : (
        <Fade>
          <React.Fragment>
            <div>
              <MDBCard
                className="mt-4 pb-4 pt-4"
                style={{ padding: "15px 30px" }}
              >
                <h2 className="title">News</h2>
                <hr className="line" />
                <MDBRow>
                  {data.allHomes.edges[0].node.newsList &&
                    data.allHomes.edges[0].node.newsList.map((item, index) => {
                      return (
                        <MDBCol
                          md="4"
                          className="key d-flex align-items-stretch"
                          key={index}
                        >
                          <NewsCard
                            id={index}
                            link={item.articleLink.url}
                            image={item.articleImage.url}
                            byline={item.articleAuthor}
                            article={item.articlePreviewCopy}
                            headline={item.articleTitle}
                          />
                        </MDBCol>
                      );
                    })}
                </MDBRow>
              </MDBCard>
            </div>
            <MDBCard className="mt-5">
              <h2 className="title">Blog</h2>
              <hr className="line" />
              <MDBRow>
                {data.allHomes.edges[0].node.blogPostList &&
                  data.allHomes.edges[0].node.blogPostList.map(
                    (item, index) => {
                      return (
                        <MDBCol md="12" className="key" key={index}>
                          <BlogCard
                            id={index}
                            link={""}
                            image={item.blogPostImage.url}
                            byline={item.blogPostAuthor}
                            article={item.blogPostCopy}
                            headline={item.blogPostTitle}
                            date={item.blogPostDate}
                          />
                        </MDBCol>
                      );
                    }
                  )}
              </MDBRow>
            </MDBCard>
          </React.Fragment>
        </Fade>
      )}
    </MDBContainer>
  );
}

const ALL_HOME_PAGE = gql`
  query homePage {
    allHomes {
      edges {
        node {
          headerImage
          newsList {
            articleLink {
              ... on _ExternalLink {
                url
              }
            }
            articleTitle
            articleAuthor
            articleImage
            articlePostDate
            articlePreviewCopy
          }
          blogPostList {
            blogPostTitle
            blogPostCopy
            blogPostAuthor
            blogPostDate
            blogPostImage
          }
        }
      }
    }
  }
`;
