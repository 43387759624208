import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard } from "mdbreact";
import Card from "../../components/newsCard";
import Twitter from "../../components/Twitter";
import Jumbotron from "../../components/Jumbotron";
import Fade from "react-reveal/Fade";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Redirect } from "react-router-dom";
import Loader from "../../animations/loader/index";

import "./style.css";

export default function Home() {
  const { loading, error, data } = useQuery(ALL_HOME_PAGE);
  if (error) {
    return <Redirect to="/404" />;
  }

  return (
    <MDBContainer style={{ minHeight: "100vh" }} className="my-5 pt-5">
      {loading ? (
        <Loader />
      ) : (
        <Fade>
          <React.Fragment>
            <Jumbotron
              firstTitle="Decoding"
              secondTitle="Disinformation"
              imageUrl={data.allHomes.edges[0].node.headerImage.url}
            />
            <MDBRow>
              <MDBCol lg="9">
                <MDBCard className="pb-4 position">
                  <h2 className="title">News</h2>
                  <hr className="line" />
                  <MDBRow>
                    {data.allHomes.edges[0].node.newsList &&
                      data.allHomes.edges[0].node.newsList.map(
                        (item, index) => {
                          return (
                            <MDBCol
                              className="key d-flex align-items-stretch"
                              md="6"
                              key={index}
                            >
                              <Card
                                id={item.articleTitle}
                                link={item.articleLink}
                                image={item.articleImage.url}
                                byline={item.articleAuthor}
                                article={item.articlePreviewCopy}
                                headline={item.articleTitle}
                              />
                            </MDBCol>
                          );
                        }
                      )}
                  </MDBRow>
                </MDBCard>
              </MDBCol>
              <MDBCol lg="3" className="mx-auto">
                <MDBRow>
                  <MDBCol size="12">
                    <Twitter handle="memetixx" limit="4" />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </React.Fragment>
        </Fade>
      )}
    </MDBContainer>
  );
}

const ALL_HOME_PAGE = gql`
  query landingPage {
    allHomes {
      edges {
        node {
          headerImage
          newsList {
            articleLink {
              ... on _ExternalLink {
                url
              }
            }
            articleTitle
            articleAuthor
            articleImage
            articlePostDate
            articlePreviewCopy
          }
          blogPostList {
            blogPostTitle
            blogPostCopy
            blogPostAuthor
            blogPostDate
            blogPostImage
          }
        }
      }
    }
  }
`;
