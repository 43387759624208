import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h1`
  width: 100%;
  text-align: center;
`;

export default function Error() {
  return (
    <Wrapper>
      <Title>Oppps, looks like we have a 404</Title>
    </Wrapper>
  );
}
