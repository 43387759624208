import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const config = {
  apiKey: "AIzaSyA3fU8HCq_RR6-XeLh04ALkDLFa28vScbA",
  authDomain: "memetix-f39f8.firebaseapp.com",
  databaseURL: "https://memetix-f39f8.firebaseio.com",
  projectId: "memetix-f39f8",
  storageBucket: "",
  messagingSenderId: "430139697199",
  appId: "1:430139697199:web:d14d902d47d0a763"
  };

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.firestore = app.firestore;
  }

  //Auth API for SignIn
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  //Auth API for SignOut
  doSignOut = () => this.auth.signOut();  

}

export default Firebase;