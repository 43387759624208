import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Loader from "../../animations/loader/index";
import Fade from "react-reveal/Fade";
import { RichText } from "prismic-reactjs";
import { Redirect } from "react-router-dom";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

export default function About() {
  const { loading, error, data } = useQuery(ABOUT_PAGE);
  if (error) {
    return <Redirect to="/404" />;
  }
  return (
    <MDBContainer style={{ minHeight: "100vh" }} className="my-5 pt-5">
      {loading ? (
        <Loader />
      ) : (
        <Fade>
          <div className="pt-4">
            <h1>Memetix</h1>
            <RichText render={data.allAboutPages.edges[0].node.description} />
            <br />
            <h1>The Team</h1>
            <MDBRow>
              {data.allAboutPages.edges[0].node.aboutTeam &&
                data.allAboutPages.edges[0].node.aboutTeam.map(
                  (item, index) => {
                    return (
                      <React.Fragment>
                        <MDBCol key={index} md="2" className="mb-3">
                          <img
                            src={item.teamImage.url}
                            height="170px"
                            style={{ minWidth: "170px", maxWidth: "170px" }}
                            alt={item.alt}
                          ></img>
                        </MDBCol>
                        <MDBCol key={index} md="10" className="mb-3">
                          <RichText render={item.teamProfileDescription} />
                        </MDBCol>
                      </React.Fragment>
                    );
                  }
                )}
            </MDBRow>
            <br></br>
            <h1>Collaborations</h1>
            <MDBRow>
              {data.allAboutPages.edges[0].node.aboutCollaborations &&
                data.allAboutPages.edges[0].node.aboutCollaborations.map(
                  (item, index) => {
                    return (
                      <React.Fragment>
                        <MDBCol
                          key={`collaboration-${index}`}
                          md="2"
                          className="mb-3"
                        >
                          <img
                            src={item.collaborationImage.url}
                            height="170px"
                            alt={item.alt}
                          ></img>
                        </MDBCol>
                        <MDBCol
                          key={`collaboration-${index}`}
                          md="10"
                          className="mb-3"
                        >
                          <RichText render={item.collaborationDescription} />
                        </MDBCol>
                      </React.Fragment>
                    );
                  }
                )}
            </MDBRow>
            {data.allAboutPages.edges[0].node.aboutContributors &&
              data.allAboutPages.edges[0].node.aboutContributors.map(
                (item, index) => {
                  return (
                    <React.Fragment>
                      <RichText render={item.aboutDescription} />
                    </React.Fragment>
                  );
                }
              )}
          </div>
        </Fade>
      )}
    </MDBContainer>
  );
}

const ABOUT_PAGE = gql`
  query aboutPage {
    allAboutPages {
      edges {
        node {
          _meta {
            id
          }
          description
          aboutTeam {
            teamImage
            teamProfileDescription
          }
          aboutCollaborations {
            collaborationImage
            collaborationDescription
          }
          aboutContributors {
            aboutDescription
          }
        }
      }
    }
  }
`;
