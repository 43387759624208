import React from "react";
import { MDBJumbotron, MDBRow, MDBCol, MDBCardTitle } from "mdbreact";
import fakeNews from "../../image/fakeNews.jpg";
import "./style.css";

const JumbotronPage = ({ imageUrl, firstTitle, secondTitle }) => {
  console.log(imageUrl);
  return (
    <MDBRow>
      <MDBCol>
        <MDBJumbotron fluid style={{ padding: 0 }}>
          <MDBCol
            className="py-5 px-4 my-5"
            style={{ backgroundImage: `url(${imageUrl})` }}
            id="fakenews"
          >
            <MDBCol md="5">
              <MDBCardTitle className="h1-responsive" id="title1">
                {firstTitle && firstTitle}
              </MDBCardTitle>
              <MDBCardTitle className="h1-responsive" id="title2">
                {secondTitle && secondTitle}
              </MDBCardTitle>
            </MDBCol>
          </MDBCol>
        </MDBJumbotron>
      </MDBCol>
    </MDBRow>
  );
};

export default JumbotronPage;
