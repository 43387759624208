import { LoaderWrapper } from "./styles";
import React from "react";

const Loader = () => (
  <LoaderWrapper>
    <div class="preloader">
      <div class="preloader-spin"></div>
    </div>
  </LoaderWrapper>
);

export default Loader;
