import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText
} from "mdbreact";
import "./style.css";
import { RichText } from "prismic-reactjs";

const Card = props => {
  return (
    <MDBCard id={props.id}>
      <img
        src={props.image}
        style={{ height: "210.61px", objectFit: "cover" }}
      />
      <a href={props.link.url} target="_blank">
        <MDBCardBody>
          <MDBCardTitle
            style={{ fontWeight: "bold", color: "black" }}
            className="line-clamp-2"
          >
            {props.headline}
          </MDBCardTitle>
          <MDBCardText>{props.byline}</MDBCardText>
          <MDBCardText
            style={{ fontWeight: "bold", color: "black" }}
            className="line-clamp-3"
          >
            <RichText render={props.article} />
          </MDBCardText>
        </MDBCardBody>
      </a>
    </MDBCard>
  );
};

export default Card;
