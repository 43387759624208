import React from "react";
import { MDBContainer, MDBRow, MDBFooter, MDBIcon } from "mdbreact";

const FooterPage = () => {
  return (
    <MDBFooter color="black" className="font-small pt-4 mt-4">
      <MDBContainer className="text-center">
          <MDBRow>
            <a href="https://www.facebook.com/memetix.org/" target="_blank" className="ml-auto">
                <MDBIcon fab icon="facebook-f" size="lg"/>
            </a>
            <a href = "mailto: info@memetix.org" className="mx-4">
                <MDBIcon far icon="envelope" size="lg"/>
            </a>
            <a href="https://twitter.com/memetixx" target="_blank" className="mr-auto">
                <MDBIcon fab icon="twitter" size="lg" className="white-text"/>
            </a>
          </MDBRow>

      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          <a href='./signin' target="_blank" >&copy;</a> {new Date().getFullYear()} Copyright: Memetix
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default FooterPage;